<template>
  <app-card-item
    class="mt-4 p-4 h-fit"
  >
    <p class="font-medium mb-2">
      {{ t('users.profile.avatar.section.title') }}
    </p>

    <p class="mb-2 text-sm">
      {{ t('users.profile.avatar.section.description') }}
    </p>

    <form-group
      name="default_avatar"
      type="select"
      :label="false"
      :form-control-props="{
        searchable: false,
        options: defaultAvatars,
      }"
    >
      <template #control-vue-multiselect-option="option">
        <img
          :src="option.url"
          alt="avatar"
          class="w-10 mr-2"
        >
        {{ option.label }}
      </template>

      <template #control-vue-multiselect-singlelabel="option">
        <img
          :src="option.url"
          alt="avatar"
          class="w-5 mr-1"
        >
        {{ option.label }}
      </template>
    </form-group>

    <!-- Delete profile picture -->
    <div class="text-center">
      <app-button
        font-size="sm"
        :label="t('users.profile.avatar.section.cta')"
        emphasis="low"
        @click="uploadDefaultAvatar"
      />
    </div>
  </app-card-item>
</template>

<script setup>
import { inject } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { defaultAvatar } from '@shared/http/api'
import useSelectOptions from '@shared/hooks/form/selectOptions'
import AppButton from '@shared/components/ui/AppButton.vue'
import AppCardItem from '@shared/components/ui/card/AppCardItem.vue'
import FormGroup from '@shared/components/form/FormGroup.vue'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

// ---------- SELECT ----------

const {
  defaultAvatars,
} = useSelectOptions()

// ---------- FORM ----------

const form = inject('form')

function uploadDefaultAvatar() {
  defaultAvatar({
    data: {
      attributes: {
        avatar: form.value.values.default_avatar,
      },
    },
  })
    .then(() => {
      router.push({ name: 'show-profile' })
      store.commit(
        'flashes/ADD_FLASH',
        {
          message: t('users.profile.avatar.success'),
          type: 'success',
        },
      )
    })
}
</script>
