import { useI18n } from 'vue-i18n'

import useBreadcrumb from '@shared/hooks/breadcrumbs'

export default function breadcrumbUser() {
  const { t } = useI18n()
  const { forceLink } = useBreadcrumb()

  /**
   * Breadcrumb for index page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function index() {
    return [
      {
        text: t('breadcrumbs.users.users'),
        link: false,
        to: '/users',
      },
    ]
  }

  /**
   * Breadcrumb for profile page
   *
   * @returns {[{link: boolean, text: string, to: string}]}
   */
  function profile() {
    return [
      {
        text: t('breadcrumbs.users.profile'),
        link: false,
        to: '/profile',
      },
    ]
  }

  /**
   * Breadcrumb for edit page
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function profileEdit() {
    let breadcrumbs = profile()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('breadcrumbs.common.edit'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for credential edit page
   *
   * @param user
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function credentialEdit() {
    let breadcrumbs = profile()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('breadcrumbs.users.edit_credential'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for show page
   *
   * @param user
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function show(user) {
    let breadcrumbs = index()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: user.label,
        link: false,
        to: `/users/${user.id}`,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for share page
   *
   * @param user
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function report(user) {
    let breadcrumbs = show(user)
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('breadcrumbs.common.report'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  /**
   * Breadcrumb for delete page
   *
   * @returns {*|{link: boolean, text: string, to: string}[]}
   */
  function profileDelete() {
    let breadcrumbs = profile()
    breadcrumbs = forceLink(breadcrumbs)

    breadcrumbs.push(
      {
        text: t('breadcrumbs.common.delete'),
        link: false,
        to: null,
      },
    )

    return breadcrumbs
  }

  return {
    profile,
    profileEdit,
    profileDelete,
    credentialEdit,
    show,
    report,
    index,
  }
}
