<template>
  <form
    id="user-profile-form"
    novalidate
    @submit.prevent="handleSubmit"
  >
    <form-global-errors class="mb-5" />

    <fieldset
      :disabled="submitting"
    >
      <teleport-wrapper
        to="#header-scrolling-replacement"
      >
        <app-button
          v-if="isModeBankAccount"
          form="user-profile-form"
          feature="save"
          type="submit"
          :disabled="submitting || invalid"
          :loading="submitting"
          class="float-right"
        />
      </teleport-wrapper>

      <user-form-fields
        :resource="resource"
        :mode="mode"
        class="clear-both"
      />

      <app-button
        v-if="isModeBankAccount"
        feature="save"
        type="submit"
        :disabled="submitting || invalid"
        :loading="submitting"
        class="float-right mt-4"
      />
    </fieldset>
  </form>
</template>

<script setup>
import { computed } from 'vue'

import useForm from '@shared/hooks/form/form'
import AppButton from '@shared/components/ui/AppButton.vue'
import FormGlobalErrors from '@shared/components/form/FormGlobalErrors.vue'
import UserFormFields from '@extranet/components/resources/user/UserFormFields.vue'

const props = defineProps({
  // JSON API resource used to
  // populate the form fields
  resource: {
    type: Object,
    default: () => ({}),
  },
  // Is the form currently submitting
  // via an ajax request
  submitting: {
    type: Boolean,
    default: false,
  },
  // Additional form errors not generated
  // by VeeValidate rules
  additionalErrors: {
    type: Object,
    default: () => ({}),
  },
  // Edit mode
  // Possible values: "profile", "currency", "locale"
  mode: {
    type: String,
    default: 'profile',
  },
})

const isModeBankAccount = computed(() => (
  props.mode !== 'bank_account'
))

// ---------- FORM ----------

const emits = defineEmits([
  'submitted',
])

const {
  handleSubmit,
  invalid,
} = useForm(props, { emits })
</script>
