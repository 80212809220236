<template>
  <teleport-wrapper
    to="#breadcrumbs"
  >
    <app-breadcrumb
      :breadcrumbs="breadcrumbs"
    />
  </teleport-wrapper>

  <user-credential-form
    :resource="user"
    :additional-errors="userFormErrors"
    :submitting="submitting"
    :success="success"
    :send-cooldown="sendCooldown"
    @submitted="handleSubmit"
  />
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

import breadcrumbUser from '@extranet/breadcrumbs/user'
import {
  sendCredentialVerification as apiSendCredentialVerification,
} from '@shared/http/api'
import UserCredentialForm from '@extranet/components/resources/user/UserCredentialForm.vue'
import AppBreadcrumb from '@extranet/components/ui/AppBreadcrumb.vue'

const store = useStore()

const success = ref(false)

const submitting = ref(false)
const user = ref(store.state.auth.user)
const userFormErrors = ref({})

function handleSubmit(attributes) {
  submitting.value = true

  // Create a new verification procedure
  apiSendCredentialVerification({
    data: {
      attributes,
    },
  }).then(() => {
    success.value = true
    triggerSendCooldown()
  }).catch(() => {
    success.value = false
  }).finally(() => {
    submitting.value = false
  })
}

// ---------- BREADCRUMBS ----------

const { credentialEdit } = breadcrumbUser()

const breadcrumbs = computed(() => (
  credentialEdit()
))

// ---------- SEND COOLDOWN ----------

const sendCooldown = ref(0)

function triggerSendCooldown() {
  sendCooldown.value = 60
}

setInterval(() => {
  if (sendCooldown.value > 0) {
    sendCooldown.value -= 1
  }
}, 1000)
</script>
